import React from 'react';
import Header from '../components/navigation/TopNavigation';

import './about.scss';
import Footer from '../components/navigation/Footer';
import { Helmet } from 'react-helmet';

const About: React.FC = () => {
    return (
        <div id="AboutScreen">
              <Helmet>
                <title>GShake | About Us</title>
            </Helmet>
            <Header />
            <div className='about-content'>
                <h1 className='about-header'>Welcome to GShake!</h1>
                <h2>First Shake-To-Earn Telegram mini-app</h2>
                <p>Instantly win memecoins with every shake and gear up for the highly anticipated $GSHK airdrop. Powered by the TON blockchain, GSHAKE turns your phone into a 21st-century Gold Rush tool, delivering real rewards and non-stop excitement.</p>
                <h2 style={{marginTop: '20px', marginBottom: "-20px"}}>Our Mission</h2>
                <p>TTo create an engaging and rewarding airdrop experience for players while delivering real value through instant crypto rewards.</p>
            </div>
            <Footer />
        </div>
    );
}

export default About;