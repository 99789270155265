import React from 'react';
import Header from '../components/navigation/TopNavigation';
import { Helmet } from 'react-helmet';
import Footer from '../components/navigation/Footer';

import './about.scss';

const HowToPlay: React.FC = () => {
    return (
        <div id="AboutScreen">
            <Helmet>
                <title>GShake | How to play</title>
            </Helmet>
            <Header />
            <div className='about-content'>
                <h1 className='about-header'> Shake to Earn</h1>
                <ul>
                    <li>Shake your phone to reveal gold nuggets and memecoins.</li>
                    <li>Tap to collect nuggets and memecoins.</li>
                    <li>Every 10 shakes reveals more nuggets.</li>
                </ul>
                <h1 className='about-header'>Invite Friends</h1>
                <ul>
                    <li>Invite friends to join.</li>
                    <li>Get a % of their earnings.</li>
                    <li>Enjoy big paydays as your team grows!</li>
                </ul>
                <h1 className='about-header'>Participate in Community</h1>
                <ul>
                    <li>GSHAKE constantly gives rewards for community members</li>
                    <li>It is more fun to spend time with other individuals</li>
                </ul>
                <h1 className='about-header'>Earn more with Super Shakes</h1>
                <ul>
                    <li>Guaranteed Memecoin Rewards.</li>
                    <li>Available in the Shop.</li>
                    <li>Six Tiers for Bigger Wins.</li>
                </ul>
            </div>
            <Footer />
        </div>
    );
}

export default HowToPlay;