import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as Telegram } from '../../assets/telegramBotton.svg';
import { ReactComponent as X } from '../../assets/xBotton.svg';

import './MobileMenu.scss';

interface Props {
    isOpen: boolean;
    closeMenu: () => void;
}

const MobileMenu: React.FC<Props> = ({ isOpen, closeMenu }) => {
    return (
        <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
            <div className="close" onClick={closeMenu}>
                <Close />
            </div>
            <NavLink 
            to="/" 
            className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
            >
            Home
            </NavLink>
            <NavLink 
            to="/about" 
            className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
            >
            About
            </NavLink>
            <NavLink 
            to="/how-to-play" 
            className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
            >
            How to play
            </NavLink>
            <NavLink 
            to="/rules" 
            className={({ isActive }) => isActive ? 'menu-item active' : 'menu-item'}
            >
            Rules
            </NavLink>
            <a
                href="https://gitbook.gshake.io/gshake"
                target="_blank"
                rel="noopener noreferrer"
                className="menu-item"
            >
                Whitepaper
            </a>
            <div className="mobile-social-icons">
                <a href="https://x.com/GSHAKE_game" className="mobile-social-icon" style={{marginRight: '16px'}} target='_blank' rel="noreferrer"><X /></a>
                <a href="https://t.me/gshake_ann" className="mobile-social-icon" target="_blank" rel="noreferrer"><Telegram/></a>
            </div>
        </div>
    );
};

export default MobileMenu;