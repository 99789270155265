import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import HomeScreen from './screens/home';
import About from './screens/about';
import HowToPlay from './screens/howToPlay';
import PrivacyPolicy from './screens/privacyPolicy';
import FAQ from './screens/FAQ';
import Rules from './screens/rules';

import './App.scss';

function App() {
  
  return (
    <div className="App">
        <Router>
          <Routes>
              <Route path='/' element={<HomeScreen />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<HomeScreen />} />
              <Route path="/how-to-play" element={<HowToPlay />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/rules" element={<Rules />} />
              <Route path="*" element={<HomeScreen />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
